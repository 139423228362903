import { Ctrl } from ":mods";
import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { Profile } from "..";

export function ProfileRoutes(props: Ctrl.Routes.model.DashboardView) {
  return (
    <>
      <Route path={[ROUTES.profile.settings]} element={<Profile {...props} />} />
    </>
  );
}
